<script>
export default {
  name: "AllProducts",
  data() {
    return {
      products: [],
      filters: [],
      countries: [],
      regions: [],
      icons: [
        'mdi-chevron-double-down',
        'mdi-filter-variant',
        'mdi-check',
        'mdi-menu',
        'mdi-magnify',
        'mdi-shield-search',
        'mdi-chevron-left',
        'mdi-glass-wine',
        'mdi-checkbox-marked',
        'mdi-close',
        'mdi-bottle-wine',
        'mdi-plus',
        'mdi-minus',
        'fas fa-glass-whiskey'
      ]
    };
  },
  computed: {
    client() {
      return this.$store.state.client;
    },
    grouped_products() {
      return this.$store.state.grouped_products;
    },
  },

  methods: {
    getCountries() {
      vinaApi
        .get(`/countries`)
        .then((response) => {
          this.countries = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRegions() {
      vinaApi
        .get(`/regions`)
        .then((response) => {
          this.regions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    legalBack() {
      let hash = localStorage.vina_hash;
      if (hash != 'undefined')
        this.$router.push('/pravne-informacije')
    },
  },

  created() {
    this.getCountries();
    this.getRegions();
  },
}
</script>

<template>
  <div>
    <div>
      <v-container class="w-100">
        <v-btn color="#272c33" depressed height="50" dark class="main-btn pl-2 mr-4 mb-4"
               @click="legalBack">
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t('back') }}
        </v-btn>
      </v-container>
    </div>
    <template v-for="(group, index) in Object.keys(grouped_products)">
      <div v-for="(product, index) in grouped_products[index]" style="display: inline; color: white">
        <img
            v-if="product.image_url"
            height="50px"
            width="50px"
            :src="product.image_url"
        />
        <video
            v-if="product.video_url"
            height="50px"
            width="50px"
            muted
        >
          <source :src="product.video_url" type="video/mp4" />
        </video>
      </div>
    </template>
    <template v-for="(client_filter, index) in Object.keys(client.client_filters)">
      <div style="display: inline" v-for="(filter, index) in client.client_filters[client_filter]">
        <img
            v-if="filter.image_url"
            height="50px"
            width="50px"
            :src="filter.image_url"
        />
      </div>
    </template>
    <div style="display: inline" v-for="(country, index) in countries">
      <img
          v-if="country.image_url"
          height="50px"
          width="50px"
          :src="country.image_url"
      />
      <img
          v-if="country.shape_image_url"
          height="50px"
          width="50px"
          :src="country.shape_image_url"
      />
    </div>
    <div style="display: inline" v-for="(region, index) in regions">
      <img
          v-if="region.image_url"
          height="50px"
          width="50px"
          :src="region.image_url"
      />
      <img
          v-if="region.shape_image_url"
          height="50px"
          width="50px"
          :src="region.shape_image_url"
      />
    </div>
    <template v-for="(client_filter, index) in client.available_filters">
      <div style="display: inline" v-for="(filter, index) in client_filter">
        <img v-if="filter.image_url" height="50px" width="50px" :src="filter.image_url" />
      </div>
    </template>
    <div>
      <v-icon color="white" v-for="(icon, index) in icons" :key="index">{{ icon }}</v-icon>
    </div>
    <div>
      <v-img
          :src="require('@/assets/img/preporuka.png')"
          height="50px"
          width="50px"
      ></v-img>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
